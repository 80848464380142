// public method for encoding
export const base64encode = function (input) {
    return window.btoa(input);
}

// public method for decoding
export const base64decode = function (input) {
    console.log('decode',input);
    return window.atob(input);
}
