<template>
    <div class="h-100 w-100 flex-in-middle nokia" v-show="page_ready" >
        <div class="login-wrap">
            <div class="left-part">
                <div class="title">Welcome to Digital Media</div>
                <div class="line"></div>
                <div class="text">
                    Welcome to Digital Media, we live stream events, conferences, exhibitions... Can be
                    shown in the form of video, recording the most exciting moment to convey to everyone
                    every success.
                </div>
            </div>
            <div class="right-part">
                <el-tabs v-model="tab" stretch v-el-tabs-large-header>
                    <el-tab-pane label="SSO" name="sso" class="position-relative tab-pane"
                                 :class="tab==='sso'?'active':''">
                        <div class="flex-in-middle" style="height: 220px;">
                            <el-button type="dark-blue" size="large" class="w-100" style="font-size: 20px;" @click="NokiaSSO">
                                NOKIA SSO
                            </el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="LOCAL" name="local" class="position-relative tab-pane"
                                 :class="tab==='local'?'active':''">
                        <div style="margin-top: 34px;">
                            <el-input v-model="login.id" clearable placeholder="Your ID" v-el-round-input></el-input>
                        </div>
                        <div style="margin-top: 34px;">
                            <el-input v-model="login.password" show-password type="password" clearable placeholder="Password" v-el-round-input></el-input>
                        </div>
                        <div style="margin-top: 43px;">
                            <el-button type="dark-blue" round class="w-100" @click="submitForm">Login</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import SignIn from "@/views/mixins/signin-mixin"
import "@/common/directives/el-tabs-large-header"
import "@/common/directives/el-round-input"

export default {
    mixins: [SignIn],
}
</script>

<style scoped lang="scss">

.nokia {
    background-image: url("/tenants/nokia/sign-in.jpg");
    background-size: cover;

    .login-wrap {
        width: 800px;
        height: 402px;
        background-image: url("/tenants/nokia/nokia-login-bg.png");
        padding: 47px 65px 60px 27px;
        display: flex;

        .left-part {
            color: white;
            margin-left: 22px;
            margin-top: 19px;
            width: 319px;
            height: 203px;

            .title {
                font-size: 1.5rem;
                font-weight: bold;
                height: 58px;
            }

            .line {
                width: 56px;
                height: 5px;
                background-color: white;
            }

            .text {
                margin-top: 21px;
            }
        }

        .right-part {
            color: black;
            margin-top: 13px;
            margin-left: 104px;
            width: 267px;
            height: 281px;

            .tab-pane {
                &.active {
                    animation:pane-move 1000ms;
                }
            }
            @keyframes pane-move
            {
                0% { opacity: 0; }
                100% {opacity: 1; }
            }

        }
    }
}

</style>
