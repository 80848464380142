import Vue from 'vue';

Vue.directive('elTabsLargeHeader', {
    inserted(el) {
        const bar = el.querySelector('.el-tabs__active-bar');
        if (bar) {
            bar.style = 'background-color:black';
        }
        const item = el.querySelector('.el-tabs__nav');
        item.classList.add('el-tabs-large-header');
    }
});
