import Vue from 'vue';

Vue.directive('elRoundInput', {
    inserted(el) {
        const bar = el.querySelector('.el-input__inner');
        if (bar) {
            bar.style = 'background-color:#f5f5f5;border-radius:50px;text-align:center';
        }

    }
});
