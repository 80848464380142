import OathMixin from "../../common/mixins/oauth-mixins";
import CommonMixin from "@/common/mixins/common-mixin"
import {mixToken} from "@/plugins/mix-token";

export default {
    name: "sign-in",
    mixins: [OathMixin, CommonMixin],
    data() {
        return {
            ready: false,
            admin_login: false,
            error_message: 'error message',
            show_login_error: false,
            background_images: [],
            login: {
                id: '',
                password: ''
            },
            bg_image: "",
            logo: '',
            tab: 'sso',
            process_sso: false,
            page_ready: false,
            jumping:false,
        }
    },
    created() {
        if (this.user.token && this.user.name) {
            this.jumping = true;
            this.afterSignIn();
            return;
        }

        if (this.$organization.background_images) {
            this.background_images = this.$organization.background_images;
        }

        this.bg_image = "/assets/sign-in.jpg";
        this.logo = "/assets/logo.png";

        if (this.$route.query._sso) {
            // 这是诺基亚的SAML登录，直接跳转到SSO
            window.location.href = '/sso?_t=' + (this.$route.query._t || encodeURIComponent("/portal"));
            // this.page_ready = true;
        } else if (this.$route.query.utoken) {
            this.$api('User.Saml', {token: this.$route.query.utoken}).then(res => {
                const _t = decodeURIComponent(this.$route.query._t) || '/portal';
                if (res.data.success) {
                    this.setUserInfo(res.data.data);
                    this.$router.push(_t);
                } else {
                    this.$messageBox.error(this.$t('ERROR.SSO-ERROR'), null, () => {
                        window.location.href = this.$organization.signin_url + '_t=' + _t;
                    });
                }
            });
        } else {
            this.page_ready = !(this.$route.query.ticket || this.$route.query.code || this.$route.query.utoken);
        }
        this.setWindowTitle();
        if (!this.$organization.sso) {
            this.admin_login = true;
            this.tab = 'local';
        }
    },
    methods: {
        resetPassword() {
            const vm = this;
            if (vm.login.id) {
                vm.$affirm('我们将向您的邮箱发送一份包含有密码的邮件, 是否继续?').then(() => {
                    vm.$api('ResetPassword', vm.login, true).then(() => {
                        vm.$messageBox.success('密码已经发出，请检查您的邮箱。');
                    })
                })
            } else {
                vm.$notice.error('请重新输入账号/邮箱');
            }
        },
        afterSignIn() {
            const vm = this;
            let url = this.$route.query._t ? decodeURIComponent(this.$route.query._t) : '/portal';
            if (url === "/portal") {
                if (this.user.role_level == 4 && !this.$isMobile()) {
                    url = "/admin";
                }
            }
            vm.$router.replace(url);
        },
        submitForm() {
            const vm = this;
            if (!vm.login.id || !vm.login.password) {
                vm.$notice.error(this.$t('MESSAGE.USERID-PASSWORD'));
            } else {
               const credential = mixToken(vm.login.id + ':' + vm.login.password, true)
                vm.$api('User.SignIn', {c:credential}, true).then(response => {
                    vm.setUserInfo(response.data.data);
                    this.afterSignIn();
                });
            }
        },
        handleSSO() {
            if (this.$organization.sso) {
                this.jumpToSSO();
            } else {
                window.location.href = '/sso?_t=' + this.$route.query._t;
            }
        },
        NokiaSSO() {
            window.location.href = '/sso?_t=' + this.$route.query._t;
        }
    },
}
