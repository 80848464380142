import store from '@/store'

export const mixToken = function (token, force) {
    if ((store.state.preset.app && store.state.preset.app.encrypt_token) || force) {
        const timestamp = Date.now().toString();
        const to_encode = encodeURIComponent(token) + "_" + timestamp;
        const reversed = to_encode.split('').reverse().join('');  // Reverse the string
        const final_str = btoa(reversed);  // Base64 encode
        const ret = btoa(final_str + "_" + timestamp);
        return ret.split('').reverse().join('')  // Add second timestamp and encode again
    }
    return token
}
